import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddSize() {
  const [value, setValue] = useState({name:'', shortName:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/size`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Size">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter size name:" value={value.name} lavel="Size name:" />
        <Input1 onChange={set} name="shortName" placeholder="Enter size Short name:" value={value.shortName} lavel="Size short name:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddSize;