import React, { useState, useEffect } from 'react';
import { Button1, Delete } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Select, Option, Input1 } from  '../../components/Input';
import Table, { Tr, Td } from '../../components/Table';
import url from '../../url';

function FlashSale(){
  const [serviceGroup, setServiceGroup] = useState([]);
  const [serviceGroupId, setServiceGroupId] = useState('');
  const [service, setService] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [product, setProduct] = useState([]);
  const [productId, setProductId] = useState('');
  const [productS, setProductS] = useState([]);
  const [flashSale, setFlashSale] = useState({});
  const [flashSaleStatus, setFlashSaleStatus] = useState("OFF");
  const [time, setTime] = useState(0);


  function cFS(){

    const myDate = new Date();
    myDate.setHours(myDate.getHours() + parseInt(time));

    fetch(`${url}/package/flashSale`,{
      method:"PUT",
      body: JSON.stringify({horus:time, endTime: myDate.getTime(), status:flashSaleStatus}),
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        fetch(`${url}/package/flashSale`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setFlashSale(data.data);
            setTime(data.data.hours);
            setFlashSaleStatus(data.data.status);
          }
        });
      }
    });


  }

  function send(){
    fetch(`${url}/package/flashSale`,{
      method:"POST",
      body: JSON.stringify({productId}),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        fetch(`${url}/package/flashSale`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setProductS(data.data.packages);
            setProductId("");
          }
        });
      }
    });
  }

  useEffect(()=>{
    fetch(`${url}/package/flashSale`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setFlashSale(data.data);
        setProductS(data.data.packages);
        setTime(data.data.hours);
        setFlashSaleStatus(data.data.status);
      }
    });
  },[]);


  useEffect(()=>{
    fetch(`${url}/serviceGroup?limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setServiceGroup(data.result.data)
      }
    });
  },[]);

  useEffect(()=>{
    fetch(`${url}/service/search?search=${serviceGroupId}&limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setService(data.result.data)
      }
    });
  },[serviceGroupId]);

  useEffect(()=>{
    fetch(`${url}/package/search?search=${serviceId}&limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setProduct(data.result.data);
      }else{
        setProduct([]);
      }
    });
  },[serviceId]);

  function remove(idd){

    fetch(`${url}/package/flashSale`,{
      method:"DELETE",
      body: JSON.stringify({productId:idd}),
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){

        fetch(`${url}/package/flashSale`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setProductS(data.data.packages)
          }
        });

      }
    });


  }


  const d = new Date(flashSale.endTime);

  return (<>

      <Form1 title="Create Flash Sale">
        <div className=" text-white text-xs md:text-base flex flex-wrap justify-center">
          <div>
            <span className=" font-bold pl-4">Status: </span><span>{flashSale.status}</span>
          </div>
          <div>
            <span className=" font-bold pl-4">Total hours: </span><span>{flashSale.hours}</span>
          </div>
          <div>
            <span className=" font-bold pl-4">End Time: </span><span>{d.toLocaleString()}</span>
          </div>
        </div>
        <Select onChange={(e)=> setFlashSaleStatus(e.target.value)}  name="flashSaleStatus" value={flashSaleStatus}  id="flashSaleStatus"  lavel="Flash Sale :">
            <Option value="OFF">OFF</Option>
            <Option value="ON">ON</Option>
        </Select>
        <Input1 onChange={(e)=>{setTime(e.target.value)}} type="number" name="time" value={time} placeholder="Enter Hours for flash sale:" />
        <Button1 click={cFS} name="Save" />
      </Form1>

  
      <Form1 title="Add Product">
        <Select onChange={(e)=> setServiceGroupId(e.target.value)}  name="serviceGroupId" value={serviceGroupId}  id="serviceGroupId"  lavel="Select service group :">
          <Option value="" disable={true}>Select a service group</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select onChange={(e)=> setServiceId(e.target.value)}  name="serviceId" value={serviceId}  id="serviceId"  lavel="Select service :">
          <Option value="" disable={true}>Select a service </Option>
          {
              service.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={(e)=> setProductId(e.target.value)}  name="productId" value={productId}  id="productId"  lavel="Select Product :">
          <Option value="" disable={true}>Select a product </Option>
          {
              product.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Button1 click={send} name="Save" />
      </Form1>

    <Table to="" name="" rowNames={["#","Name","Purchase Price","Discount Price","Sale Price","Image", "Actions"]}  limit={0} data={{data:[],next:{page: 1, limit: 0},previous:{page: 0, limit: productS.length}}} width='w-full'>
      {
          productS.map(({_id, name, purchasePrice, price, discountPrice, phone, address, img}, index)=>{
              return(
                  <Tr key={index}>
                      <Td>{1+index}</Td>
                      <Td>{name}</Td>
                      <Td width='w-40'>{purchasePrice} ৳</Td>
                      <Td width='w-40'>{discountPrice} ৳</Td>
                      <Td width='w-40'>{price} ৳</Td>
                      <Td width='w-40 '><img className=' w-20 text-center block mx-auto' src={img} alt="" /></Td>
                      <Td>
                          <Delete id={_id} click={()=>remove(_id)} />
                      </Td>
                  </Tr>
              );
          })
        }
    </Table>

  </>)
}

export default FlashSale;