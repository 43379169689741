import React, { useState }from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import SideBarToggler from './SideBarToggler';
import MinSideBarToggler from './MinSideBarToggler';

function Sidebar({bar, mbar}) {

    const [active, setActive] = useState(-1);
    console.log('sidebar')

    const data = [
        {
            title:'User Management',
            icon: <i className="fa fas fa-users pr-2"></i>,
            links:[
                {name:'Users',link:'/user'},
                // {name:'Roles',link:'/d'},
                // {name:'Sales Commission Agents',link:'/'}
            ]
        },
        // {
        //     title:'Branch Management',
        //     icon:<i className="fa-solid fa-earth-americas pr-2"></i>,
        //     links:[
        //         // {name:'Users',link:'/'},
        //         // {name:'Roles',link:'/d'},
        //         // {name:'Sales Commission Agents',link:'/'},
        //         {name:'Area',link:'/area'},
        //         {name:'Branch',link:'/branch'},
        //     ]
        // },
        {
            title:'Contacts',
            icon: <i className="fa fas fa-address-book  pr-2"></i>,
            links:[
                // {name:'Staffs',link:'/allWorkers'},
                {name:'Customers',link:'/allCustomer'},
            ]
        },
        {
            title:'Category',
            icon: <i className="fa-solid fa-table-cells-large pr-2"></i>,
            links:[
                {name:' Categorys',link:'/allCategorys'},
                {name:'Add Category',link:'/addCategory'},
            ]
        },
        {
            title:'Sub Category',
            icon: <i className="fa-solid fa-table-cells pr-2"></i>,
            links:[
                {name:'Sub Categorys',link:'/allSubCategorys'},
                {name:' Add Sub Category',link:'/addSubCategory'}

            ]
        },
        // {
        //     title:'Sub Services',
        //     icon: <i className="fa-solid fa-table-cells-large pr-2"></i>,
        //     links:[
        //         {name:'All Sub Services',link:'/allSubService'},
        //         {name:' Add Sub Service',link:'/addSubService'},
        //     ]
        // },
        {
            title:'Product',
            icon: <i className="fa fas fa-cubes pr-2"></i>,
            links:[
                {name:'All Products',link:'/allProducts'},
                // {name:'Add Product',link:'/addProduct'},
                {name:'All Units',link:'/AllUnit'},
                {name:'All Sizes',link:'/AllSize'},
                {name:'All Colors',link:'/AllColor'},
                {name:'Flash Sale',link:'/flashSale'},
                // {name:'Add Unit',link:'/addUnit'},
                // {name:'Add Category',link:'/addCategory'},
                // {name:'All Categories',link:'/allCategory'},
                // {name:'Add Brand',link:'/addBrand'},
                // {name:'All Brands',link:'/allBrand'},
            ]
        },
        {
            title:'Coupon',
            icon: <i className="fa-regular fa-money-bill-1 pr-2"></i>,
            links:[
                {name:'All Coupon',link:'/allCoupon'},
                {name:'Add Coupon',link:'/addCoupon'},
            ]
        },
        {
            title:'Sell',
            icon: <i className="fa-solid fa-cart-shopping pr-2"></i>,
            links:[
                {name:'All sales',link:'/allSale'},
                // {name:'Add Sale',link:'/'},
            ]
        },
        // {
        //     title:'Vendor',
        //     icon: <i className="fa-solid fa-user-tag pr-2"></i>,
        //     links:[
        //         {name:'Vendor Group',link:'/allVendorGroup'},
        //         {name:'Vendors',link:'/allVendor'},
        //         // {name:'Add Sale',link:'/'},
        //     ]
        // },
        // {
        //     title:'Stock Transfers',
        //     icon: <i className="fa fas fa-truck  pr-2"></i>,
        //     links:[
        //         {name:'List Stock Transfers',link:'/'},
        //         {name:'Add Stock Transfer',link:'/'}
        //     ]
        // },
        // {
        //     title:'Stock Adjustment',
        //     icon: <i className="fa fas fa-database  pr-2"></i>,
        //     links:[
        //         {name:'List Stock Adjustments',link:'/'},
        //         {name:'Add Stock Adjustment',link:'/'}
        //     ]
        // },
        // {
        //     title:'Expenses',
        //     icon: <i className="fa fas fa-minus-circle pr-2"></i>,
        //     links:[
        //         {name:'List Expenses',link:'/'},
        //         {name:'Add Expense',link:'/'},
        //         {name:'Expense Categories',link:'/'}
        //     ]
        // },
        // {
        //     title:'Blog',
        //     icon: <i className="fa-solid fa-blog pr-2"></i>,
        //     links:[
        //         {name:'Blog Category',link:'/allBlogCategory'},
        //         {name:'Blog Sub Category',link:'/allBlogSubCategory'},
        //         {name:'Blogs',link:'/blogs'},
        //     ]
        // },
        // {
        //     title:'Job Vacancy',
        //     icon: <i className="fa-solid fa-users-rectangle pr-2"></i>,
        //     links:[
        //         {name:'Vacancy',link:'/vacancy'},
        //         {name:'Job Request',link:'/jobRequest'},
        //     ]
        // },
        // {
        //     title:'Reports',
        //     icon: <i className="fa-solid fa-chart-line  pr-2"></i>,
        //     links:[
        //         {name:'All Todos',link:'/allTodo'},
        //         {name:'All Service Request',link:'/allServiceRequest'},
        //     ]
        // },
        // {
        //     title:'Settings',
        //     icon: <i className="fa fas fa-cog pr-2"></i>,
        //     links:[
        //         {name:'Business Settings',link:'/'},
        //         {name:'Business Locations',link:'/'},
        //         {name:'Invoice Settings',link:'/'},
        //         {name:'Barcode Settings',link:'/'},
        //         {name:'Barcode Settings',link:'/'},
        //         {name:'Receipt Printers',link:'/'},
        //         {name:'Tax Rates',link:'/'}
        //     ]
        // },
    ]
  return (
      <>
        {
          (mbar === false)?
          <div className={`${bar} hidden  md:w-1/4 lg:w-1/5 xl:w-2/12 float-left transition-all duration-700 md:ml-0 h-screen  md:flex flex-col `}>
            <Link to='/dashboard'><div className=' py-4 h-screen-2 bg-dark-blue-2 text-center text-2xl text-white overflow-hidden'>EasySheba</div></Link>
            <div className=' h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> Dashboard </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <SideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
       :
       <div className=' w-[calc(0%+56px)] float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/dashboard'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>ES</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
   } 

        <div className=' w-[calc(0%+56px)] md:hidden float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/dashboard'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>KH</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
      </>



  )
}

export default Sidebar