import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Option, Select } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditSale({props}) {
  const [value, setValue] = useState({received:0, discount:0, status:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){


    fetch(`${url}/sale/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });



  }

  useEffect(()=>{

    fetch(`${url}/sale/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{

        if(data.status === true){
           setValue({received: data.data.received, discount: data.data.discount, status:data.data.status});
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[id]);




  return (
    <Form1 title="Edit Sale">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} type="number" name="received" value={value.received} lavel="Received:" />
        <Input1 onChange={set} type="number" name="discount" value={value.discount} lavel="Discount:" />
        <Select onChange={set} name="status" value={value.status} lavel="Status:">
            <Option value="pending">Pending</Option>
            <Option value="accepted">Accepted</Option>
            <Option value="delivered">Delivered</Option>
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditSale;