import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../components/Button';
import Filter from '../../components/Filter';
import Table, { Tr, Td, Img } from '../../components/Table';
import { Option, Select } from '../../components/Input';
import Loader from '../../components/Loader';
import url from '../../url';
import { View } from '../../components/Button';

function AllPackage() {

const [packag, setPackage] = useState({data:[],next:{},previous:{}});
const [serviceGroup, setServiceGroup] = useState([]);
const [selectSG, setSelectSG] = useState("");
const [service, setService] = useState([]);
const [selectS, setSelectS] = useState("");
// const [subService, setSubService] = useState([]);
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);



function delet(id){
    fetch(`${url}/package/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/package?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setPackage(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setPackage(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}

useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

},[]);

useEffect(()=>{

    // setLoader(true);
    fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        // setLoader(false);
        if(data.status === true){
            setService(data.result.data);
        }else{
            setService([])
        }
    });
},[selectSG]);


// useEffect(()=>{
//     setLoader(true);
//     fetch(`${url}/subService/search?page=${1}&limit=${0}&search=${selectS}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//         setLoader(false);
//         if(data.status === true){
//             setSubService(data.result.data);
//         }else{
//             setSubService([])
//         }
//     });
// },[selectS]);





useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/package?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setPackage(data.result);
            }
        });
    }

    return () => setPackage({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setPackage(data.result);
            }
        });
    
    }

    return () => setPackage({data:[],next:{},previous:{}});
},[page, limit, src]);



function search(e){
    setSrc(e.target.value);
}


function byCat(cat){
    setLoader(true);
    fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${cat}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setPackage(data.result);
        }else{
            setPackage({data:[],next:{},previous:{}})
        }
    });
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <div>
        <Filter >
            <div className=' w-80 '>
                <Select onChange={(e)=>setSelectSG(e.target.value)} value={selectSG}  name="selectSG"  id="selectSG"  lavel="Select category :">
                    <Option value="" disable={true}>Select a category</Option>
                    <Option value="" >All service group</Option>
                {
                    serviceGroup.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

            <div className=' w-80 '>
                <Select onChange={(e)=>{ setSelectS(e.target.value); byCat(e.target.value);}} value={selectS}  name="serviceS"  id="serviceS"  lavel="Select Sub category :">
                    <Option value="" disable={true}>Select a sub category</Option>
                    <Option value="" >All category </Option>
                {
                    service.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>


        </Filter>

        <Table to="/addProduct" name="Add Product" rowNames={["#","Name","Description", "Purchase price", "Sale price", "Discount price", "Quantity", "Unit", "Sizes", "Colors", "Category", "image", "date", "Actions"]}  srcFunc={search} data={packag} width='w-full'>
                {
                    packag.data.map(({_id, name, purchasePrice, price, discountPrice, quantity, service, unit, size, sizes, color, colors, img, date}, index)=>{
                        const d = new Date(date);
                        return(
                            <Tr key={index}>
                                <Td>{((packag.previous.page*packag.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td width='w-40'><View to={"/viewProduct/"+_id} /></Td>
                                <Td >{purchasePrice} ৳</Td>
                                <Td >{price} ৳</Td>
                                <Td >{discountPrice} ৳</Td>
                                <Td >{quantity} </Td>
                                <Td>{unit.name}</Td>
                                <Td>{size?sizes.toString():""}</Td>
                                <Td>{color?colors.toString():""}</Td>
                                <Td>{service.name}</Td>
                                <Td>{<Img>{img}</Img>}</Td>
                                <Td>{d.toLocaleDateString()}</Td>
                                <Td>
                                    <Edit to={"/editProduct/"+_id} />
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
    </div>

}</>)
}

export default AllPackage;