import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditSize() {
  const [value, setValue] = useState({name:'', shortName:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/size/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/size/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data[0].name, shortName: data.data[0].shortName});
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });

  },[id]);




  return (
    <Form1 title="Edit size">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter size name:" value={value.name} lavel="size name:" />
        <Input1 onChange={set} name="shortName" placeholder="Enter size short name:" value={value.shortName} lavel="size short name:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default EditSize;