import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Select, Option } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddPackage() {
  const [value, setValue] = useState({name:'', description:'', file:{}, serviceId:'', unitId:'', size:false, color:false, purchasePrice:0, price: 0, discountPrice: 0, quantity: 0});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [siz, setSiz] = useState([]);
  const [col, setCol] = useState([]);
  const [serviceGroup, setServiceGroup] = useState([]);
  const [selectSG, setSelectSG] = useState("");
  const [service, setService] = useState([]);
  const [unit, setUnit] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function setSize(){
    if(value.size === false){
      setValue({...value, "size":true});
    }else{
      setValue({...value, "size":false});
    }
  }

  function setColor(){
    if(value.color === false){
      setValue({...value, "color":true});
    }else{
      setValue({...value, "color":false});
    }
  }

  function getSizes(e){
    if(e.target.checked){
      setSizes([...sizes, e.target.value]);
    }else{
      const newSizes = sizes.filter((data)=>{
        return(data !== e.target.value);
      });
      setSizes(newSizes);
    }
  }

  function getColors(e){
    if(e.target.checked){
      setColors([...colors, e.target.value]);
    }else{
      const newColors = colors.filter((data)=>{
        return(data !== e.target.value);
      });
      setColors(newColors);
    }
  }


  function setFile(e){
    setValue({...value, file:e.target.files[0]});
  }


  useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

  },[]);

  useEffect(()=>{

    fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

        if(data.status === true){
            setService(data.result.data);
        }else{
            setService([])
        }
    });
  },[selectSG]);

  // useEffect(()=>{

  //   fetch(`${url}/subService/search?page=${1}&limit=${0}&search=${selectS}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
  //       if(data.status === true){
  //           setSubService(data.result.data);
  //       }else{
  //           setSubService([])
  //       }
  //   });
  // },[selectS]);






  useEffect(()=>{

    fetch(`${url}/unit?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
      if(data.status === true){;
        setUnit(data.result.data)
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });

  },[]);

  useEffect(()=>{

    fetch(`${url}/size?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
      if(data.status === true){;
        setSiz(data.result.data)
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });

  },[]);

  useEffect(()=>{

    fetch(`${url}/color?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
      if(data.status === true){;
        setCol(data.result.data)
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });

  },[]);

  function send(){
      const formData = new FormData();

      formData.append("name", value.name);
      formData.append("description", value.description);
      formData.append("price", value.price);
      formData.append("discountPrice", value.discountPrice);
      formData.append("purchasePrice", value.purchasePrice);
      formData.append("quantity", value.quantity);
      formData.append("unitId", value.unitId);
      formData.append("size", value.size);
      formData.append("sizes", sizes);
      formData.append("color", value.color);
      formData.append("colors", colors);
      formData.append("serviceId", value.serviceId);
      formData.append("photo", value.file);

      fetch(`${url}/package`,{
        method:"POST",
        body: formData,
        credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });
  }


  return (
    <Form1 title="Add Product">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter product name:" value={value.name} lavel="Product name:" />
        {/* <Textarea onChange={set} name="description" placeholder="Enter package description:" value={value.description} lavel="Package description:" /> */}
        <Input1 onChange={set} type="number" name="purchasePrice" placeholder="Enter purchase price:" value={value.purchasePrice} lavel="Purchase Price:" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter price:" value={value.price} lavel="Sale Price:" />
        <Input1 onChange={set} type="number" name="discountPrice" placeholder="Enter discount price:" value={value.discountPrice} lavel="Discount price:" />
        <Input1 onChange={set} type="number" name="quantity" placeholder="Enter quantity:" value={value.quantity} lavel="Quantity:" />

        <Select onChange={set}  name="unitId" value={value.unitId}  id="input4"  lavel="Select unit:">
          <Option value="" disable={true}>Select a unit</Option>
          {
              unit.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={(e)=>setSelectSG(e.target.value)}  name="SelectSG" value={selectSG}  id="SelectSG"  lavel="Select Category:">
          <Option value="" disable={true}>Select a category</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={set}  name="serviceId" value={value.serviceId}  id="serviceId"  lavel="Select sub category:">
          <Option value="" disable={true}>Select a sub category</Option>
          {
              service.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        {/* <Select onChange={set}  name="subServiceId" value={value.subServiceId}  id="input4"  lavel="Select sub service:">
          <Option value="" disable={true}>Select a sub service</Option>
          {
              subService.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select> */}
        <div className=" w-full h-auto flex items-center justify-center">
          <div className=" w-1/2 h-auto">
            <div className=" pt-3">
              <label className=" text-white inline-block pr-4 cursor-pointer" htmlFor="size">Active Size: </label>
              <input className=' cursor-pointer' onChange={setSize} type="checkbox" name="size" id="size" value={value.size} checked={(value.size === true)? true:false}  />
            </div>
            {
              value.size?
              <div className=" flex flex-col">
                {
                  siz.map((data, index)=>{
                    return(
                      <div key={index}>
                        <label className=" text-white inline-block pr-4 cursor-pointer" htmlFor={data.shortName}>{data.shortName} </label>
                        <input className=' cursor-pointer' onChange={getSizes} type="checkbox" name={data.shortName} id={data.shortName} value={data.shortName}  />
                    </div>
                    )
                  })
                }
              </div>:<></>
            }
          </div>
          <div className=" w-1/2 h-auto ">
            <div className=" pt-3">
              <label className=" text-white inline-block pr-4 cursor-pointer" htmlFor="color">Active Color: </label>
              <input className=' cursor-pointer' onChange={setColor} type="checkbox" name="color" id="color" value={value.color} checked={(value.color === true)? true:false}  />
            </div>
            {
              value.color?
              <div className=" flex flex-col">
                {
                  col.map((data, index)=>{
                    return(
                      <div key={index}>
                        <label className=" text-white inline-block pr-4 cursor-pointer" htmlFor={data.name}>{data.name} </label>
                        <input className=' cursor-pointer' onChange={getColors} type="checkbox" name={data.name} id={data.name} value={data.name}  />
                    </div>
                    )
                  })
                }
              </div>:<></>
            }

          </div>
        </div>

        <Input1 onChange={setFile} type="file" name="quantity" placeholder="Upload photo:" lavel="Upload photo:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddPackage;